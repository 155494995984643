import React from 'react';

import Section from '@heureca/shared/components/Section';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';

import * as styles from './private.module.scss';

const Private = () => {
  const { translate } = useTranslations();

  return (
    <Section name="Charte de vie privée" title="Charte de vie privée">
      <div className={styles.content}>
        <h1>{translate('privacy.why.title')}</h1>
        <p>{translate('privacy.why.text')}</p>
        <h1>{translate('privacy.who.title')}</h1>
        <p>{translate('privacy.who.text')}</p>
        <ul>
          <li>INGENUM SPRL</li>
          <li>Rue Comte Cornet 19, 7022 Maisières</li>
          <li>Numéro d’entreprise : BE0696782573</li>
          <li>E-mail : contact@ingenum.be</li>
        </ul>
        <h1>{translate('privacy.which.title')}</h1>
        <p>{translate('privacy.which.text')}</p>
        <ul>
          <li>{translate('privacy.which.identity')}</li>
          <li>{translate('privacy.which.locality')}</li>
          <li>{translate('privacy.which.connexion')}</li>
        </ul>
        <h1>{translate('privacy.finality.title')}</h1>
        <p>{translate('privacy.finality.text')}</p>
        <ul>
          <li>{translate('privacy.finality.account')}</li>
          <li>{translate('privacy.finality.invoicing')}</li>
          <li>{translate('privacy.finality.customize')}</li>
          <li>{translate('privacy.finality.productsAndServices')}</li>
          <li>{translate('privacy.finality.detectProblems')}</li>
          <li>{translate('privacy.finality.sendOffer')}</li>
        </ul>
        <p>{translate('privacy.finality.endText')}</p>
        <h1>{translate('privacy.rights.title')}</h1>
        <h2>{translate('privacy.rights.warrantyTitle')}</h2>
        <p>{translate('privacy.rights.warrantyText')}</p>
        <h2>{translate('privacy.rights.accessRightsTitle')}</h2>
        <p>{translate('privacy.rights.accessRightsText')}</p>
        <h2>{translate('privacy.rights.rectificationTitle')}</h2>
        <p>{translate('privacy.rights.rectificationText')}</p>

        <h2>{translate('privacy.erasureRight.title')}</h2>
        <p>{translate('privacy.erasureRight.text')}</p>
        <ul>
          <li>{translate('privacy.erasureRight.data')}</li>
          <li>{translate('privacy.erasureRight.consent')}</li>
          <li>{translate('privacy.erasureRight.contradiction')}</li>
          <li>{translate('privacy.erasureRight.illegalTreatment')}</li>
          <li>{translate('privacy.erasureRight.obligation')}</li>
          <li>{translate('privacy.erasureRight.endText')}</li>
        </ul>
        <h2>{translate('privacy.erasureRight.limitationTitle')}</h2>
        <p>{translate('privacy.erasureRight.limitationText')}</p>
        <h2>{translate('privacy.erasureRight.contradictionTitle')}</h2>
        <p>{translate('privacy.erasureRight.contradictionText')}</p>
        <h2>{translate('privacy.erasureRight.transferTitle')}</h2>
        <p>{translate('privacy.erasureRight.transferText')}</p>
        <h2>{translate('privacy.erasureRight.removeConsentTitle')}</h2>
        <p>{translate('privacy.erasureRight.removeConsentText')}</p>
        <h1>{translate('privacy.myRights.title')}</h1>
        <p>{translate('privacy.myRights.text')}</p>
        <h1>{translate('privacy.complaint.title')}</h1>
        <p>
          {translate('privacy.complaint.text')}
          {' ('}
          <a href="https://www.privacycommission.be/">
            https://www.privacycommission.be/
          </a>
          )
        </p>
      </div>
    </Section>

  );
};

export default Private;
